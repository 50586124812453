import { useState } from 'react';
import useInView from 'react-cool-inview';

const LazyLoader = (props: any) => {
    const [isVisible, setVisible] = useState(false)
    const { observe, inView } = useInView({      
        "rootMargin": "80px 0px",
        onLeave: () => {
            // Triggered when the target leaves the viewport
            setVisible(true)
          },
    });
    return <section ref={observe}>{(inView || isVisible) && props.children}</section>
}

export default LazyLoader;